$(function () {
  setTimeout(function () {
    var $slide = $(".m_slider").slick({
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 500,
      speed: 1800,
      fade: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false
    }).on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("slide-animation");
      },
      afterChange: function () {
        $(".preve-slide", this).removeClass("preve-slide　slide-animation");
      }
    });
    $slide.find(".slick-slide").eq(0).addClass("slide-animation");
  }, 1300);
});

$(function () {
  let hSize = $(window).height();
  $('.top_visual').height(hSize);
});

$(window).resize(function () {
  let hSize = $(window).height();
  $('.top_visual').height(hSize);
});
