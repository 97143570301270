var headerH = $("header").outerHeight(true);

function FixedAnime() {
  var scroll = $(window).scrollTop();
  if (scroll >= headerH) {
    $('header').addClass('heightmin');
  } else {
    $('header').removeClass('heightmin');
  }
}
$(window).scroll(function() {
      FixedAnime();
});
$(window).on('load', function() {
      FixedAnime();
});
