$(".openbtn").click(function () {
  $(this).toggleClass('active');
  $("#g-nav, h1").toggleClass('panelactive');
  $(".circle-bg").toggleClass('circleactive');
});

$("#g-nav a").click(function () {
  $(".openbtn").removeClass('active');
  $("#g-nav, h1").removeClass('panelactive');
  $(".circle-bg").removeClass('circleactive'); 
});
