$(function () {
  setTimeout(function () {
    var $slide = $(".m_slider").slick({
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 1200,
      speed: 1800,
      fade: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false
    }).on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("slide-animation");
      },
      afterChange: function () {
        $(".preve-slide", this).removeClass("preve-slide　slide-animation");
      }
    });
    $slide.find(".slick-slide").eq(0).addClass("slide-animation");
  }, 3300);
});

$(function () {
  let hSize = $(window).height();
  $('.top_visual').height(hSize);
});

$(window).resize(function () {
  let hSize = $(window).height();
  $('.top_visual').height(hSize);
});



  function SmoothTextAnime() {
    $('.smoothTextTrigger').each(function () {
      var elemPos = $(this).offset().top - 10;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass('smoothTextAppear');
      }
    });
  }
  $(window).scroll(function () {
    SmoothTextAnime();
  });
  $(window).on('load', function () {
    SmoothTextAnime();
  });

  function fadeFluffy() {
    $('.fadeFluffyTrigger').each(function () {
      var elemPos = $(this).offset().top - 10;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass('fadeFluffy');
      }
    });
  }
  $(window).scroll(function () {
    fadeFluffy();
  });

  function fadeFluffyload() {
    $('.fadeFluffyTriggerload').each(function () {
      var elemPos = $(this).offset().top - 10;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass('fadeFluffyload');
      }
    });
  }
  $(window).on('load', function () {
    fadeFluffyload();
  });

  function blurfade() {
    $('.blurTrigger').each(function () {
      var elemPos = $(this).offset().top - 20;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass('blurfade');
      }
    });
  }
  $(window).scroll(function () {
    blurfade();
  });

  function slideAnime() {
    $('.rightAnime').each(function () {
      var elemPos = $(this).offset().top - 50;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass("slideAnimeRightLeft");
        $(this).children(".rightAnimeInner").addClass("slideAnimeLeftRight");
      } else {
        $(this).removeClass("slideAnimeRightLeft ");
        $(this).children(".rightAnimeInner").removeClass("slideAnimeLeftRight");
      }
    });
  }
  $(window).on('load', function () {
    slideAnime();
  });
  $(window).scroll(function () {
    slideAnime();
  });

  function slideAnimeleft() {
    $('.leftAnime').each(function () {
      var elemPos = $(this).offset().top - 50;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll >= elemPos - windowHeight) {
        $(this).addClass("slideAnimeLeftRight");
        $(this).children(".leftAnimeInner").addClass("slideAnimeRightLeft");
      } else {
        $(this).removeClass("slideAnimeLeftRight");
        $(this).children(".leftAnimeInner").removeClass("slideAnimeRightLeft");
      }
    });
  }
  $(window).on('load', function () {
    slideAnimeleft();
  });
  $(window).scroll(function () {
    slideAnimeleft();
  });
